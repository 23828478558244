import { defineStore } from "pinia";
import axios from 'axios';
import { useRuntimeConfig } from "nuxt/app";
import { useValidationStore } from "~/stores/account/validations.store";

export const useCustomerActionStore = defineStore("customerAction", {
  state: () => ({
    customerActions: {},
  }),
  getters: {
    accountApiUrl: () => {
      const config = useRuntimeConfig();
      return config.public.vcrmAccountApiUrl as string;
    },
    customerActionsGetter: (state) => state.customerActions,
  },
  actions: {
    getVcrmAccountToken() {
      if (!document) {
        return false;
      }
      const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
      if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
        return vue_cookie.split("=")[1];
      }
    },
    async fetchCustomerActionsByCustomerId(customer_id: string) {
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.get(`${this.accountApiUrl}/customer-actions?filter[customer_id]=${customer_id}`, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web",
        }
      })
        .then((res) => {
          this.customerActions = res.data
        })
    },
    async addNoteToCustomer(data: any) {
      const validationStore = useValidationStore();
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.post(`${this.accountApiUrl}/customer-actions`, data,{
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web",
        }
      })
        .then((res) => {
          this.fetchCustomerActionsByCustomerId(data.customer_id);
        }).catch((res) => {
          if (res.status == 422) {
            validationStore.setValidationsAction(res?.response?.data)
          }
        })
    },
  }
});