<script setup>
import { onMounted } from 'vue';
import { useCustomerStore } from '~/stores/account/customers.store';
import { useCustomerActionStore } from '~/stores/account/customer-actions.store';
import { useRuntimeConfig } from '#app';

const customerActionStore = useCustomerActionStore();
const customerStore = useCustomerStore();

const route = useRoute();
const config = useRuntimeConfig();
const id = ref(route.params.id);
const isLoading = ref(true);
const customer = ref(undefined);
const customerActions = computed(() => customerActionStore.customerActionsGetter);
const addNoteForm = ref({
  customer_id: parseInt(id.value),
  notes: undefined,
})

onMounted(async () => {
  customer.value = await customerStore.fetchCustomer(id.value);
  await customerActionStore.fetchCustomerActionsByCustomerId(id.value);

  if (!customer.value) {
    showError({
      statusCode: 404,
      statusMessage: "Page Not Found"
    });
  }

  isLoading.value = false;
});

const addNewNote = () => {
  if (!addNoteForm.value.notes) return;
  customerActionStore.addNoteToCustomer(addNoteForm.value);
  addNoteForm.value.notes = '<p></p>';
}
</script>
<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.show_customer')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>
    <div v-if="!isLoading" class="px-4">
      <div class="bg-white p-3 rounded-lg text-center my-3">
        <div class="text-lg font-medium border-b pb-2">{{ customer.full_name }}</div>
        <div class="flex flex-col text-left mt-2 font-medium">
          <div class="flex">
            <span class="w-1/3">{{ $t('client.mobile_number') }}</span>
            <span class="me-2 font-normal">({{ customer.countries.phone_code }}) {{ customer.mobile }}</span>
            <a v-if="customer.mobile" :href="`https://wa.me/${customer.countries.phone_code}${customer.mobile}`" target="_blank"
              class="flex items-center text-xs bg-[#25D366] rounded-md px-1 text-white"><i
                class="fab fa-whatsapp me-1"></i>{{ $t('client.whatsapp') }}</a>
          </div>
          <div class="flex ">
            <span class="w-1/3">{{ $t('client.email') }}</span>
            <span class="font-normal">{{ customer.email }}</span>
          </div>
          <div class="flex ">
            <span class="w-1/3">{{ $t('client.category') }}</span>
            <span class="font-normal">{{ customer.agent_customer_categories?.name }}</span>
          </div>
          <div class="flex ">
            <span class="w-1/3">{{ $t('client.source') }}</span>
            <span class="font-normal">{{ customer.agent_customer_sources?.name }}</span>
          </div>
        </div>
      </div>

      <div>
        <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.notes') }}</h3>
        <WEditor name="notes" type="textarea" placeholder="Notes" v-model:value="addNoteForm.notes" />
        <button @click="addNewNote" class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center my-3">
          <i class="fa-solid me-3"></i>
          {{ $t('client.add') }}
        </button>
      </div>

      <div v-for="action in customerActions?.data" class="bg-white p-3 flex flex-col mb-3 rounded-lg">
        <div class="flex flex-col">
          <div class="flex justify-between">
            <div class="text-lg font-medium">{{ action.agent_name }}</div>
            <!-- <div><i class="fa-solid fa-user"></i></div> -->
          </div>
          <div class="text-xs text-gray-600 ">{{ new Date(action.updated_at ||
            action.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            })
            }}
          </div>
        </div>
        <div v-html="action.notes" class="my-1"></div>
        <div class="item-center inline-flex rounded-md relative w-min  bg-indigo-900 text-white">
          <a v-if="action.attachment" :href="`${config.public.mediaUrl}/${action.attachment}`" target="_blank"
            class="flex items-center py-1 px-2">
            <i class="fa-solid fa-arrow-down me-2"></i>
            <span>{{ $t('client.attachment') }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="relative h-screen" v-else>
      <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <span class="loader"></span>
      </div>
    </div>
  </NuxtLayout>
</template>